export default {
    fields: {
        customertitleid: {
            visible: true,
            required: false,
            label: {
                nl: 'Titel',
                en: 'Title',
            },
            description: {
                nl: '',
                en: '',
            },
        },
        firstname: {
            visible: true,
            required: false,
            label: {
                nl: 'Voornaam',
                en: 'First name',
            },
            description: {
                nl: '',
                en: '',
            },
        },
        middlename: {
            visible: true,
            required: false,
            label: {
                nl: 'Tussenvoegsel',
                en: 'Middle name',
            },
            description: {
                nl: '',
                en: '',
            },
        },
        lastname: {
            visible: true,
            required: true,
            label: {
                nl: 'Achternaam',
                en: 'Last name',
            },
            description: {
                nl: '',
                en: '',
            },
        },
        street1: {
            visible: true,
            required: true,
            label: {
                nl: 'Straat',
                en: 'Street',
            },
            description: {
                nl: '',
                en: '',
            },
        },
        street2: {
            visible: true,
            required: true,
            label: {
                nl: 'Huisnummer',
                en: 'House number',
            },
            description: {
                nl: '',
                en: '',
            },
        },
        street3: {
            visible: true,
            required: false,
            label: {
                nl: 'Toevoeging',
                en: 'Addition',
            },
            description: {
                nl: '',
                en: '',
            },
        },
        zip: {
            visible: true,
            required: true,
            label: {
                nl: 'Postcode',
                en: 'Zip code',
            },
            description: {
                nl: 'We leren ons publiek graag goed kennen om een zo gepast mogelijk programma te kunnen aanbieden. We sturen je alleen post wanneer je dit als voorkeur hebt aangegeven in je account.',
                en: '',
            },
        },
        city: {
            visible: true,
            required: true,
            label: {
                nl: 'Woonplaats',
                en: 'City',
            },
            description: {
                nl: '',
                en: '',
            },
        },
        countrycode: {
            visible: true,
            required: true,
            label: {
                nl: 'Land',
                en: 'Country',
            },
            description: {
                nl: '',
                en: '',
            },
        },
        phonenumber: {
            visible: true,
            required: false,
            label: {
                nl: 'Telefoonnummer',
                en: 'Phone number',
            },
            description: {
                nl: 'Wanneer we je nummer hebben kunnen we eventuele wijzigingen voor een voorstelling makkelijk aan je doorgeven. We zullen enkel hiervoor je nummer gebruiken.',
                en: 'When we have your number, we can easily inform you about changes to a performance. We will only use your number for this purpose.',
            },
        },
        languagecode: {
            visible: true,
            required: false,
            label: {
                nl: 'Taal',
                en: 'Language',
            },
            description: {
            },
        },         
        birthdate: {
            visible: true,
            required: true,
            label: {
                nl: 'Geboortedatum',
                en: 'Birthdate',
            },
            description: {
                nl: 'We leren ons publiek graag goed kennen om een zo gepast mogelijk programma te kunnen aanbieden. Als dank bieden we een presentje op je verjaardag aan.',
                en: 'We like to get to know our audience to offer a program that suits you best. As a thank you, we offer a present on your birthday.',
            },
            preferNotToSay: {
                nl: 'Zeg ik liever niet',
                en: 'Prefer not to say',
            },            
        },
    },
    customfields: {
        sex: {
            required: true,
            label: {
                nl: 'Geslacht',
                en: 'Sex',
            },
        },
        referralsource: {
            required: true,
            label: {
                nl: 'Hoe heb je van ons gehoord?',
                en: 'How did you hear about us?',
            },
        },
        referralsourcetext: {
            required: true,
            condition: contact => contact.c_referralsource === 11361,
        },
    },
};